const DOMAIN ="http://34.125.127.98:9002"
export const API={
    LOGIN:`${DOMAIN}/login`,
    SIGNUP:`${DOMAIN}/signup`,
    FILEUPLOADER:`${DOMAIN}/fileUploader`,
    USERS:`${DOMAIN}/users`
};


// const DOMAIN ="http://localhost:9002"
